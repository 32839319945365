import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { SystemStateService } from '@state-management/system-state';

export const disableOfflineModeResolver: ResolveFn<boolean> = () => {
  const systemStateService = inject(SystemStateService);

  systemStateService.setValue('isManualOffline', false);

  return true;
};
