import { Injectable, inject } from '@angular/core';
import { LocalStorageEnum } from '@enums/local-storage.enum';
import { AuthStateService } from '@state-management/auth-state';
import { MeConfigStateService } from '@state-management/me-config-state/me-config-state.service';
import { MeProfileStateService } from '@state-management/me-profile-state';
import { SystemStateService } from '@state-management/system-state/system-state.service';
import { isDateExpired } from '@utils/dates';
import { parseJWT } from '@utils/jwt-manipulation';
import { AuthService } from './auth.service';
import { OfflineIndexedDbService } from './indexed-db/offline/offline-indexed-db.service';

@Injectable({
  providedIn: 'root',
})
// ! This service is strictly for initialization purposes.
// ! All initiation processes should be encapsulated inside a private function and added to the init method.
// Primary purpose is load essential configurations from local storage into the application state.
export class InitializationService {
  private readonly authStateService = inject(AuthStateService);
  private readonly authService = inject(AuthService);
  private readonly meConfigStateService = inject(MeConfigStateService);
  private readonly meProfileStateService = inject(MeProfileStateService);
  private readonly offlineIndexedDbService = inject(OfflineIndexedDbService);
  private readonly systemStateService = inject(SystemStateService);

  init(): void {
    this.checkRefreshToken();
    this.setMeConfig();
    this.setLimits();
    this.getIsOfflineState();
    this.setCorporate();
    this.offlineIndexedDbService.init();
  }

  private setLimits(): void {
    const limits = localStorage.getItem(LocalStorageEnum.UserLimits);
    if (!limits) return;
    this.meProfileStateService.setValue('limits', JSON.parse(limits));
  }

  private setMeConfig(): void {
    const app_config = localStorage.getItem(LocalStorageEnum.AppConfig);
    if (!app_config) return;

    this.meConfigStateService.setMultipleValues(JSON.parse(app_config));
  }

  private checkRefreshToken(): void {
    const refreshToken = localStorage.getItem(LocalStorageEnum.Refresh);

    if (refreshToken) {
      this.authService.setExpirationDateFromToken(refreshToken);
      this.authStateService.setValue('refresh', refreshToken);
    }

    const exp = (!!refreshToken && parseJWT(refreshToken)?.exp) || null;
    if (isDateExpired(new Date().getTime(), exp)) {
      this.authService.logout();
    }
  }

  private getIsOfflineState(): void {
    const isOffline = localStorage.getItem(LocalStorageEnum.IsOffline);
    this.systemStateService.setValue('isManualOffline', isOffline === 'true');
  }

  private setCorporate(): void {
    const corporate = localStorage.getItem(LocalStorageEnum.Corporate);
    if (!corporate) return;
    this.meProfileStateService.setValue('corporate_id', JSON.parse(corporate));
  }
}
