import {
  HttpEvent,
  HttpHandlerFn,
  HttpParams,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { isDevMode } from '@angular/core';
import { environment } from '@environments/environment';
import { Nullable } from '@models/nullable.model';
import { Observable, catchError, tap } from 'rxjs';

const transform = (bytes: number): string => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) {
    return '0 Bytes';
  }

  const i = Math.floor(Math.log(bytes) / Math.log(1024));

  return `${Math.round(bytes / Math.pow(1024, i))} ${sizes[i]}`;
};

const log = ({
  type,
  name,
  variables,
  data,
  length,
  params,
}: {
  type: string;
  name: string;
  variables?: unknown;
  data: unknown;
  length: Nullable<number>;
  params: HttpParams;
}): void => {
  console.groupCollapsed(
    `[ %c${type}`,
    'color: green; font-weight: bold; font-size: .95rem;',
    `]: ${name.replace(environment.server_url, '')} (${transform(+length!)})`,
  );

  if (params?.keys().length) {
    console.log(
      '%cPARAMS',
      'color: orange; font-weight: bold; font-size: .95rem;',
      params.toString() || '',
    );
  }

  if (variables && Object.keys(variables).length) {
    console.log(
      '%cREQUEST',
      'color: yellow; font-weight: bold; font-size: .95rem;',
      variables ?? '',
    );
  }

  console.log(
    '%cRESPONSE',
    'color: dodgerblue; font-weight: bold; font-size: .95rem;',
    data || '',
  );
  console.groupEnd();
};

export const loggerInterceptor = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  return next(request).pipe(
    tap((result) => {
      if (!isDevMode() || !(result instanceof HttpResponse)) {
        return;
      }

      const bodyResult = result.body;
      log({
        type: request.method,
        name: request.url,
        variables: request.body,
        data: bodyResult,
        length: JSON.stringify(bodyResult)?.length || 0,
        params: request.params,
      });
    }),
    catchError((error) => {
      if (!isDevMode()) {
        throw error;
      }

      log({
        type: request.method,
        name: request.url,
        variables: request.body,
        data: error.error,
        length: null,
        params: request.params,
      });

      throw error;
    }),
  );
};
