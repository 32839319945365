export interface DelayTimeByUrlType {
  regExpUrl: RegExp;
  delay: number;
  method: 'GET' | 'POST' | 'PUT' | 'UPDATE' | 'DELETE';
  message?: string;
}

const SECOND = 1000;

export const delayTimeByUrl: DelayTimeByUrlType[] = [
  {
    regExpUrl: /\/areas\/\d+\/validate_plots$/,
    method: 'POST',
    delay: SECOND * 31,
  },
  {
    regExpUrl: /\/reports\/hydric\/evolution$/,
    method: 'POST',
    delay: SECOND * 15,
  },
  {
    regExpUrl: /\/reports\/health\/general$/,
    method: 'POST',
    delay: SECOND * 10,
  },
  {
    regExpUrl: /\/geoserver\/preview$/,
    method: 'POST',
    delay: SECOND * 8,
  },
  {
    regExpUrl: /\/geoserver\/tiles\/histograms$/,
    method: 'POST',
    delay: SECOND * 7,
  },
  {
    regExpUrl: /\/plots\/\d+\/stats$/,
    method: 'POST',
    delay: SECOND * 4,
  },
  {
    regExpUrl: /\/reports\/hydric\/general$/,
    method: 'POST',
    delay: SECOND * 3,
  },
  {
    regExpUrl: /\/geoserver\/campaign\/\d+\/geojson$/,
    method: 'POST',
    delay: SECOND * 4,
  },
  {
    regExpUrl: /\/geoserver\/campaign\/\d+\/geojson$/,
    method: 'GET',
    delay: SECOND * 4,
  },
  {
    regExpUrl: /\/areas\/\d+\/validate_lots$/,
    method: 'POST',
    delay: 1000 * 3,
  },
];
