import { authenticationGuard } from '@guards/authentication.guard';
import { NaxRoutes } from '@models/routes.model';
import { disableOfflineModeResolver } from './resolvers/disable-offline-mode.resolver';

export const APP_ROUTES: NaxRoutes = [
  {
    path: 'home',
    canMatch: [authenticationGuard],
    loadChildren: () => import('./pages/main/main.routes'),
  },
  {
    path: 'authentication',
    resolve: [disableOfflineModeResolver],
    loadChildren: () => import('./pages/authentication/authentication.routes'),
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];
