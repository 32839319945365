import {
  Component,
  computed,
  effect,
  HostBinding,
  inject,
  signal,
  untracked,
} from '@angular/core';
import { Snackbar, SnackbarService } from '@services/snackbar.service';
import { SnackbarComponent } from '../snackbar/snackbar.component';

@Component({
  selector: 'nax-snackbar-container',
  standalone: true,
  imports: [SnackbarComponent],
  templateUrl: './snackbar-container.component.html',
})
export class SnackbarContainerComponent {
  @HostBinding('class') class =
    'nax-snackbar-container pointer-events-none flex-column gap-2 p-4 align-center';

  private readonly snackbarService = inject(SnackbarService);

  private snackbarList = signal<Snackbar[]>([]);
  protected displayedSnackbarList = computed(() =>
    this.snackbarList().slice(0, 3),
  );

  constructor() {
    this.addSnackbarEffect();
  }

  protected removeSnackbar(snackbar: Snackbar): void {
    this.snackbarList.update((snackbarList) =>
      snackbarList.filter((e) => e !== snackbar),
    );
  }

  private addSnackbarEffect(): void {
    effect(() => {
      const snackbar = this.snackbarService.snackbar();
      if (!snackbar) return;
      untracked(() => this.snackbarList.update((list) => [...list, snackbar]));
    });
  }
}
