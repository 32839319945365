import { DOCUMENT } from '@angular/common';
import { inject, Injectable, isDevMode, NgZone } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { SwUpdate } from '@angular/service-worker';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { SystemStateService } from '@state-management/system-state';
import { concat, delay, filter, interval, startWith, Subscription } from 'rxjs';
import { DialogService } from './dialog.service';

@Injectable({
  providedIn: 'root',
})
export class CheckForUpdateService {
  private readonly translateService = inject(TranslateService);
  private readonly dialogService = inject(DialogService);
  private readonly isOffline = inject(SystemStateService).isOffline;
  private readonly ngZone = inject(NgZone);

  private intervalSubscription: Subscription | undefined;
  private everyMinutes$ = interval(
    environment.minutes_interval_check_new_app_version * 60 * 1000,
  ).pipe(delay(5000), startWith(0));
  private everyMinutesOnceAppIsStable$ = concat(
    toObservable(this.isOffline).pipe(delay(5000)),
    this.everyMinutes$,
  );

  private readonly swUpdate = inject(SwUpdate);
  private _document = inject(DOCUMENT);

  start(): void {
    this.intervalSubscription?.unsubscribe();
    if (!this.swUpdate.isEnabled) return;
    if (isDevMode()) {
      console.info(
        `${this.getFormattedDateTime()} - ${this.translateService.instant('SERVICE_WORKER_DISABLED')}`,
      );

      return;
    }

    console.info(
      `${this.getFormattedDateTime()} - ${this.translateService.instant('SERVICE_WORKER_ENABLED')}`,
    );

    this.ngZone.runOutsideAngular(() => {
      this.intervalSubscription = this.everyMinutesOnceAppIsStable$
        .pipe(filter(() => !this.isOffline()))
        .subscribe(async () => {
          try {
            const updateFound = await this.swUpdate.checkForUpdate();
            if (updateFound) {
              console.info(
                `${this.getFormattedDateTime()} - ${this.translateService.instant('NEW_APP_VERSION_READY')}`,
              );
              this.applyUpdate();
            } else {
              console.info(
                `${this.getFormattedDateTime()} - ${this.translateService.instant('YOU_HAVE_LAST_APP_VERSION')}`,
              );
            }
          } catch (err) {
            console.warn(
              `${this.getFormattedDateTime()} - ${this.translateService.instant('FAIL_CHECKING_NEW_APP_VERSION')}`,
              err,
            );
          }
        });
    });
  }

  getFormattedDateTime(): string {
    return new Intl.DateTimeFormat('default', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    }).format(new Date());
  }

  applyUpdate(): void {
    this.dialogService.open({
      type: 'dialog',
      title: 'NEW_APP_VERSION_READY',
      message: '',
      canCloseClickingOutside: false,
      cancelAction: this.reload.bind(this),
      cancelLabelButton: 'OK',
    });
  }

  private reload(): void {
    this.swUpdate
      .activateUpdate()
      .then(() => {
        window.location.reload();
      })
      .catch((err) => console.error('Failed to apply updates: ', err));
  }
}
