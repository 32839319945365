import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { SnackbarService } from '@services/snackbar.service';
import { SystemStateService } from '@state-management/system-state';
import { Observable, catchError, throwError } from 'rxjs';
import { httpErrorNotification } from './utils/server-error.utils';

export const serverErrorNotificationInterceptor = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const snackbarService = inject(SnackbarService);
  const isOffline = inject(SystemStateService).isOffline();

  return next(request).pipe(
    catchError((error) => {
      if (isOffline) return throwError(() => error);

      httpErrorNotification(snackbarService, error);
      return throwError(() => error);
    }),
  );
};
