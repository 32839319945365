@if (title(); as title) {
  <p class="title--medium self-start">
    {{ title | naxTranslate }}
  </p>
}

<p class="body--medium break-word">
  {{ message() | naxTranslate: messageParams() }}
</p>

<nax-cta
  class="push-right"
  scheme="neutral"
  appearance="text"
  [isIconButton]="true"
  icon="close"
  title="CLOSE"
  (click)="onClickClose()" />
