import { HttpStatusCode } from '@angular/common/http';

export interface ErrorCodeType {
  regExpUrl: RegExp;
  code: HttpStatusCode | ExtraHttpStatusCode;
  message: string;
}

enum ExtraHttpStatusCode {
  NoConnection = 0,
}

export const errorCodes: ErrorCodeType[] = [
  {
    regExpUrl: /\/token\/pair$/,
    code: HttpStatusCode.Unauthorized,
    message: 'SIGN_IN_ERROR_MESSAGE',
  },
  {
    regExpUrl: /./,
    code: HttpStatusCode.InternalServerError,
    message: 'INTERNAL_SERVER_ERROR',
  },
  {
    regExpUrl: /./,
    code: ExtraHttpStatusCode.NoConnection,
    message: 'NO_CONNECTION_ERROR_MESSAGE',
  },
];
