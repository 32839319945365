import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { API_ROUTES } from '@constants/api-routes';
import { Nullable } from '@models/nullable.model';
import { AuthService } from '@services/auth.service';
import { Observable, catchError, switchMap, throwError } from 'rxjs';
import { authRequestTreatment } from './auth.utils';

export const handleErrorsFactory = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
  authService: AuthService,
  error: unknown,
  refreshToken: Nullable<string>,
): Nullable<Observable<HttpEvent<unknown>>> => {
  return handleUnauthorizedResponse(
    request,
    next,
    authService,
    error,
    refreshToken,
  );
};

export const handleUnauthorizedResponse = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
  authService: AuthService,
  error: unknown,
  refreshToken: Nullable<string>,
): Nullable<Observable<HttpEvent<unknown>>> => {
  if (!mustRefreshToken(request, error)) return null;

  if (!refreshToken) {
    return throwError(() => error);
  }

  return authService.refreshToken(refreshToken)?.pipe(
    switchMap(({ access }) => {
      return next(authRequestTreatment(request, access));
    }),
    catchError((err) => {
      if (mustLogout(err)) {
        authService.logout();
      }
      return throwError(() => err);
    }),
  );
};

const mustLogout = (error: unknown): boolean => {
  return (
    error instanceof HttpErrorResponse &&
    !!error.url?.includes(API_ROUTES.token.refresh)
  );
};

const mustRefreshToken = (
  request: HttpRequest<unknown>,
  error: unknown,
): boolean => {
  return (
    error instanceof HttpErrorResponse &&
    !request.url.includes(API_ROUTES.token.pair) &&
    error.status === HttpStatusCode.Unauthorized
  );
};
