import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthStateService } from '@state-management/auth-state';

export const authenticationGuard = (): boolean => {
  const router = inject(Router);
  const authStateService = inject(AuthStateService);
  if (!authStateService.isLogged()) router.navigate(['/authentication/login']);
  return authStateService.isLogged();
};
