import {
  HttpBackend,
  HttpClient,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  ModuleWithProviders,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  TitleStrategy,
  provideRouter,
  withComponentInputBinding,
  withRouterConfig,
} from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { authInterceptor } from '@interceptors/auth.interceptor';
import { handleErrorsInterceptor } from '@interceptors/handle-errors.interceptor';
import { loggerInterceptor } from '@interceptors/logger.interceptor';
import { serverDelayNotificationInterceptor } from '@interceptors/server-delay-notification.interceptor';
import { serverErrorNotificationInterceptor } from '@interceptors/server-error-notification.interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { InitializationService } from '@services/initialization.service';
import { TitleStrategyService } from '@services/title-strategy.service';
import { APP_ROUTES } from './app.routes';

// AoT requires an exported function for factories
export const HttpLoaderFactory = (
  httpHandler: HttpBackend,
): TranslateHttpLoader => {
  return new TranslateHttpLoader(
    new HttpClient(httpHandler),
    './i18n/',
    '.json',
  );
};

export const DefaultTranslateModule =
  (): ModuleWithProviders<TranslateModule> =>
    TranslateModule.forRoot({
      defaultLanguage: 'es-ES',
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend],
      },
    });

export const DefaultTranslateModuleTest =
  (): ModuleWithProviders<TranslateModule> =>
    TranslateModule.forRoot({
      defaultLanguage: 'es-ES',
      useDefaultLang: true,
    });

export const appConfig: ApplicationConfig = {
  providers: [
    // TODO: provider correcto
    provideServiceWorker('custom-ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    { provide: TitleStrategy, useClass: TitleStrategyService },
    //

    // TODO: providers PENDIENTES de checkear
    provideRouter(
      APP_ROUTES,

      withComponentInputBinding(),
      //
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
        urlUpdateStrategy: 'eager',
      }),
    ),

    provideHttpClient(
      withInterceptors([
        authInterceptor,
        loggerInterceptor,
        handleErrorsInterceptor,
        serverErrorNotificationInterceptor,
        serverDelayNotificationInterceptor,
      ]),
    ),
    importProvidersFrom(DefaultTranslateModule()),
    // TODO: find the way to remove it
    provideAnimations(),
    {
      provide: APP_INITIALIZER,
      useFactory: (initializationService: InitializationService) => () => {
        initializationService.init();
      },
      multi: true,
      deps: [InitializationService],
    },
  ],
};
