import { Injectable, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { SystemStateService } from '@state-management/system-state';

@Injectable({
  providedIn: 'root',
})
export class TitleStrategyService extends TitleStrategy {
  private readonly _title = inject(Title);
  private _systemStateService = inject(SystemStateService);

  constructor() {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot): void {
    const title = this.buildTitle(routerState);
    this._title.setTitle(`${title} | NAX Solutions Platform`);
    this._systemStateService.setMultipleValues({
      activeRoute: routerState.url,
      routeTitle: title,
    });
  }
}
